<template>
    <!-- 會員紀錄 -->
    <div>會員紀錄</div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
export default {
    setup() {
        const store = useStore();

        const handleGetDonationRecords = () => {
            store
                .dispatch("handleGetDonationRecords")
                .then((res) => {
                    console.log(res);
                })
                .catch((error) => {
                    console.error("handleGetDonationRecords failed:", error);
                });
        };

        onMounted(() => {
            handleGetDonationRecords();
        });
    },
};
</script>

<style scoped></style>
