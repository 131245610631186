<template>
    <!-- 協會介紹 -->
    <div class="top_wrapper">
        <div class="page_title">協會介紹</div>
        <img class="banner" src="@/assets/banner/bn-about.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="text_section">
        <div>
            關於台灣有點溫暖毛孩協會，只要每個人心中有那麼一點溫暖，就可以用愛照亮流浪動物們
        </div>
        <div>
            成立本協會宗旨在於幫助在外流浪的毛孩們，以及偏遠山區照顧流浪動物的弱勢人士給予協助
        </div>
    </div>

    <video class="video" controls="" autoplay="" muted="">
        <source
            src="https://www.warmwarm.tw/assets/video.mp4"
            type="video/mp4"
        />
    </video>
</template>

<script>
export default {};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.text_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 16px 24px;
    font-size: 15px;
    gap: 24px;
}

.video {
    width: 100%;
}
</style>
