<template>
    <!-- 專欄文章一覽表 -->
    <div class="top_wrapper">
        <div class="page_title">專欄文章</div>
        <img class="banner" src="@/assets/banner/bn-blog.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="card-section">
        <div class="card">
            <router-link :to="{ name: 'ArticleDetail' }">
                <img src="@/assets/indexCard1.png" alt="" />
                <div class="card-text">
                    詹順貴專欄：對於流浪動物的處理困境與 建議看法
                </div>
                <hr class="title-date-hr" />
                <div class="card-date">2023/04/11</div>
            </router-link>
        </div>

        <div class="card">
            <router-link :to="{ name: 'ArticleDetail' }">
                <img src="@/assets/indexCard2.png" alt="" />
                <div class="card-text">
                    詹順貴專欄：對於流浪動物的處理困境與 建議看法
                </div>
                <hr class="title-date-hr" />
                <div class="card-date">2023/04/11</div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.card-section {
    display: flex;
    gap: 10px;
}

.card {
    width: 50%;
    box-shadow: 0 0 3px 2px #0000000d;
}
.card a {
    text-decoration: none;
    color: #809da5;
}

img {
    width: 100%;
    object-fit: contain;
}

.card-text {
    font-size: 8px;
    padding: 10px 15px;
}

.title-date-hr {
    width: 90%;
    margin: 0 auto;
}

.card-date {
    padding: 20.4px 0 15.5px 15px;
    font-size: 8px;
    line-height: 9.6px;
    color: #809da5;
}
</style>
