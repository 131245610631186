<template>
    <!-- 線上捐糧 -->
    <div class="top_wrapper">
        <div class="page_title">線上捐糧</div>
        <img class="banner" src="@/assets/banner/bn-donation_food.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="card-section">
        <div class="card" v-for="(i, idx) in items" :key="i.id">
            <div class="card_img">
                <img :src="i.image" alt="" class="centered-image" />
            </div>
            <div>{{ i.name }} {{ i.price }} 元</div>
            <div class="number-section">
                <div class="input-section">
                    <button @click="decrement(idx)">-</button>
                    <input type="number" v-model="i.quantity" />
                    <button @click="increment(idx)">+</button>
                </div>
                <button
                    type="button"
                    class="send-btn"
                    @click="
                        handlePostEcpayFood({ id: i.id, quantity: i.quantity })
                    "
                >
                    我要捐糧
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
    setup() {
        const store = useStore();

        const items = ref([]);
        // const items = ref([
        //     {
        //         id: 1,
        //         image: require("@/assets/donate01.png"),
        //         name: "狗狗營養飼料(小包5日份)",
        //         price: 500,
        //         quantity: 0,
        //     },
        //     {
        //         id: 2,
        //         image: require("@/assets/donate01.png"),
        //         name: "狗狗營養飼料(小包5日份)",
        //         price: 500,
        //         quantity: 0,
        //     },
        //     {
        //         id: 3,
        //         image: require("@/assets/donate01.png"),
        //         name: "狗狗營養飼料(小包5日份)",
        //         price: 500,
        //         quantity: 0,
        //     },
        //     {
        //         id: 4,
        //         image: require("@/assets/donate01.png"),
        //         name: "狗狗營養飼料(小包5日份)",
        //         price: 500,
        //         quantity: 0,
        //     },
        // ]);

        const donateBody = ref({
            id: 1,
            quantity: 1,
            is_regular: false,
            period_type: "D",
            frequency: 1,
            exec_times: 10,
            name: "name",
            phone: "phone",
            identity: "identity",
            email: "email",
            address: "address",
        });

        const handleGetDonateFood = () => {
            store
                .dispatch("handleGetDonateFood")
                .then((res) => {
                    const modifiedData = res.map((item) => {
                        item.quantity = 1;
                        return item;
                    });
                    items.value = modifiedData;
                })
                .catch((error) => {
                    console.error("handleGetDonateFood failed:", error);
                });
        };

        const handlePostEcpayFood = ({ id, quantity }) => {
            donateBody.value.id = id;
            donateBody.value.quantity = quantity;
            store
                .dispatch("handlePostEcpayFood", donateBody.value) // Access the value of the ref
                .then((res) => {
                    const formHtml = res.data;

                    const tempDoc = new DOMParser().parseFromString(
                        formHtml,
                        "text/html"
                    );

                    const formElement = tempDoc.querySelector("form");

                    document.body.appendChild(formElement);

                    formElement.submit();
                })
                .catch((error) => {
                    console.error("handlePostEcpayFood failed:", error);
                });
        };

        onMounted(() => {
            handleGetDonateFood();
        });

        const increment = (index) => {
            items.value[index].quantity++;
        };

        const decrement = (index) => {
            if (items.value[index].quantity > 1) {
                items.value[index].quantity--;
            }
        };

        return {
            items,
            increment,
            decrement,

            handlePostEcpayFood,
        };
    },
};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a,
.send-btn {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.card-section {
    text-align: center;
    padding: 12px 24px 24px;
}

.card-section .card:not(:last-child) {
    margin-bottom: 24px;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.card_img {
    text-align: center;
}

.centered-image {
    max-width: 100%; /* Limit the image width to its container */
    max-height: 200px; /* Set a maximum height as needed */
    margin: 0 auto; /* Center the image horizontally */
}

.number-section {
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-top: 4px;
}

.input-section {
    display: flex;
    gap: 4px;
}

.input-section input {
    width: 24px;
    text-align: center;
}

.send-btn {
    padding: 2px 6px;
}
</style>
