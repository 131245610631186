<template>
    <!-- 線上捐款 -->
    <div class="title">線上捐款方式</div>
    <div class="buttons">
        <router-link :to="{ name: 'OnlinePayment' }"> 線上刷卡</router-link>
        <router-link :to="{ name: 'RegularDonation' }"> 定期捐款</router-link>
        <router-link :to="{ name: 'ATMTransfer' }"> ATM轉帳</router-link>
        <router-link :to="{ name: 'PostalRemittance' }"> 郵政劃撥</router-link>
    </div>
    <div class="form_img">
        <img src="@/assets/donate_table.png" alt="" />
    </div>
    <div class="donate_text">
        <div>捐款捐款捐款捐款捐款捐款捐款捐款捐款</div>
        <div>捐款捐款捐款捐款捐款捐款捐款捐款捐款</div>
        <div>捐款捐款捐款捐款捐款捐款捐款捐款捐款</div>
        <div>捐款捐款捐款捐款捐款捐款捐款捐款捐款</div>
        <div>捐款捐款捐款捐款捐款捐款捐款捐款捐款</div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
    display: flex;
    justify-content: center;
    padding: 24px;
    padding-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding: 12px;
    padding-top: 0;
}

.buttons a {
    font-size: 16px;
    width: 200px;
    padding: 6px 0;
    border: 2px solid #333333;
    border-radius: 4px;
    text-decoration: none;
    color: #333333;
    text-align: center;
}

.form_img img {
    width: 100%;
    height: auto;
}

.donate_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
}
</style>
