<template>
    <swiper
        :spaceBetween="30"
        :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }"
        :pagination="{
            clickable: true,
        }"
        :modules="modules"
        class="mySwiper"
    >
        <swiper-slide
            ><img class="banner" src="@/assets/banner/logo.jpg" alt="" />
        </swiper-slide>
        <swiper-slide
            ><img class="banner" src="@/assets/banner/logo.jpg" alt="" />
        </swiper-slide>
    </swiper>

    <div class="video_section">
        <div class="video_div">
            <video class="video" controls="" autoplay="">
                <source src="@/assets/video/video.mp4" type="video/mp4" />
            </video>
        </div>
        <section class="top_section">
            <router-link :to="{ name: 'ArticleList' }" class="more-text">
                more &gt;
            </router-link>
            <div class="card-section">
                <div class="card">
                    <router-link :to="{ name: 'ArticleDetail' }">
                        <img src="@/assets/indexCard1.png" alt="" />
                        <div class="card-text">
                            詹順貴專欄：對於流浪動物的處理困境與 建議看法
                        </div>
                        <hr class="title-date-hr" />
                        <div class="card-date">2023/04/11</div>
                    </router-link>
                </div>

                <div class="card">
                    <router-link :to="{ name: 'ArticleDetail' }">
                        <img src="@/assets/indexCard2.png" alt="" />
                        <div class="card-text">
                            詹順貴專欄：對於流浪動物的處理困境與 建議看法
                        </div>
                        <hr class="title-date-hr" />
                        <div class="card-date">2023/04/11</div>
                    </router-link>
                </div>
            </div>
        </section>
    </div>

    <div class="donate-img">
        <img src="@/assets/indexDonate.png" alt="" />
    </div>
    <router-link :to="{ name: 'ArticleList' }" class="more">more..</router-link>
    <div class="foods">
        <div class="donate-food">
            <div class="wrapper">
                <img src="@/assets/donate01.png" alt="" />
            </div>
            <div class="donate-btn">
                <router-link :to="{ name: 'OnlineFoodDonation' }"
                    >線上捐糧</router-link
                >
            </div>
        </div>
        <div class="donate-food">
            <div class="wrapper">
                <img src="@/assets/donate01.png" alt="" />
            </div>
            <div class="donate-btn">
                <router-link :to="{ name: 'OnlineFoodDonation' }"
                    >線上捐糧</router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import { onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const store = useStore();
        const page = ref(1);
        const limit = ref(1);

        const handleGetHomeVideo = () => {
            store
                .dispatch("handleGetHomeVideo", {
                    page: page.value,
                    limit: limit.value,
                })
                .then((res) => {
                    console.log(res);
                })
                .catch((error) => {
                    console.error("handleGetHomeVideo failed:", error);
                });
        };

        onMounted(() => {
            handleGetHomeVideo();
        });
        return {
            modules: [Autoplay, Pagination, Navigation],
        };
    },
};
</script>

<style scoped>
.video {
    width: 100%;
}

section {
    padding: 0 10px 10px;
}

.more-text {
    color: #809da5;
    font-size: 9px;
    letter-spacing: 1px;
    text-align: right;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin-bottom: 5px;
}

.card-section {
    display: flex;
    gap: 10px;
}

.card {
    width: 50%;
    box-shadow: 0 0 3px 2px #0000000d;
}
.card a {
    text-decoration: none;
    color: #809da5;
}

img {
    width: 100%;
    object-fit: contain;
}

.card-text {
    font-size: 8px;
    padding: 10px 15px;
}

.title-date-hr {
    width: 90%;
    margin: 0 auto;
}

.card-date {
    padding: 20.4px 0 15.5px 15px;
    font-size: 8px;
    line-height: 9.6px;
    color: #809da5;
}

.donate-img {
    display: flex;
    justify-content: center;
    margin: 8px 0;
}

.donate-img img {
    width: 188px;
    height: 48px;
    object-fit: contain;
}

.more {
    color: red;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: right;
    cursor: pointer;
    display: block;
    text-decoration: none;
    width: 90%;
    margin: 5px auto;
}

.foods {
    display: flex;
    justify-content: center;
    gap: 5%;
    width: 90%;
    margin: 0 auto;
}

.donate-food {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper {
    padding: 24px;
    box-shadow: 0 0 3px 2px #0000000d;
}

.donate-food img {
    text-align: center;
}

.donate-btn {
    display: flex;
    justify-content: center;
    padding: 1rem 0.5rem;
}

.donate-btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 4px 40px;
    font-size: 14px;
}
</style>
