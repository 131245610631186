<template>
    <!-- 年度報告 -->
    <div class="top_wrapper">
        <div class="page_title">年度報告</div>
        <img class="banner" src="@/assets/banner/bn-report.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div v-for="i in items" :key="i.id">
        <div class="title">{{ i.name }}</div>
        <div class="report_img" v-for="img in i.images" :key="img.image">
            <img :src="img.image" alt="" class="centered-image" />
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
    setup() {
        const store = useStore();
        const page = ref(1);
        const limit = ref(1);

        const items = ref([]);
        const handleGetYearlyReport = () => {
            store
                .dispatch("handleGetYearlyReport", {
                    page: page.value,
                    limit: limit.value,
                })
                .then((res) => {
                    const modifiedData = res.map((item) => {
                        const images = item.images.map((img) => {
                            if (img.image.includes("http://www.warmwarm.tw")) {
                                return img;
                            } else {
                                // 在前面添加指定字符串
                                return {
                                    image: "http://www.warmwarm.tw" + img.image,
                                };
                            }
                        });
                        item.images = images;
                        return item;
                    });
                    items.value = modifiedData;
                })
                .catch((error) => {
                    console.error("handleGetYearlyReport failed:", error);
                });
        };

        onMounted(() => {
            handleGetYearlyReport();
        });

        return {
            items,
        };
    },
};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.title {
    text-align: center;
    font-size: 18px;
}

.report_img {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.centered-image {
    max-width: 100%; /* Limit the image width to its container */
    max-height: 200px; /* Set a maximum height as needed */
    margin: 0 auto; /* Center the image horizontally */
}
</style>
