export default {
    setToken(state, token) {
        state.token = token;
    },

    clearToken(state) {
        state.token = "";
    },

    setHadToken(state, hadToken) {
        state.hadToken = hadToken;
    },
};
