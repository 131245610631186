<template>
    <!-- 募款箱地圖 -->
    <div class="top_wrapper">
        <div class="page_title">募款箱地圖</div>
        <img class="banner" src="@/assets/banner/bn-map.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="areas">
        <div class="tabs">
            <div
                class="tab"
                :class="{ active: activeElement === 1 }"
                @click="setActiveElement(1)"
            >
                北部
            </div>
            <div
                class="tab"
                :class="{ active: activeElement === 2 }"
                @click="setActiveElement(2)"
            >
                中部
            </div>
            <div
                class="tab"
                :class="{ active: activeElement === 3 }"
                @click="setActiveElement(3)"
            >
                南部
            </div>
        </div>
        <div class="shops north">
            <div v-if="activeElement === 1">
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>台北市長春路200號</div>
                </div>
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>台北市長春路200號</div>
                </div>
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>台北市長春路200號</div>
                </div>
            </div>
            <div v-else-if="activeElement === 2">
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>台中市長春路200號</div>
                </div>
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>台中市長春路200號</div>
                </div>
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>台中市長春路200號</div>
                </div>
            </div>
            <div v-else>
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>高雄市長春路200號</div>
                </div>
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>高雄市長春路200號</div>
                </div>
                <div class="shop">
                    <div>寶島眼鏡仁愛店</div>
                    <div>02-23456789</div>
                    <div>高雄市長春路200號</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
export default {
    setup() {
        const activeElement = ref(1);

        const setActiveElement = (elementNumber) => {
            activeElement.value = elementNumber;
        };

        return { activeElement, setActiveElement };
    },
};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.areas {
    padding: 15px;
}

.tabs {
    display: flex;
    padding-bottom: 6px;
    gap: 6px;
}

.tabs .active {
    color: gray;
}

.tabs .tab:not(:last-child) {
    border-right: 1px solid #d2d2d2;
    padding-right: 6px;
}

.shops {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
</style>
