<template>
    <AppHeader />

    <main :style="{ marginTop: marginTop, minHeight: minHeight }">
        <router-view> </router-view>
        <div ref="formContainer"></div>
    </main>

    <AppFooter />
</template>

<script setup>
import { defineAsyncComponent, ref, onMounted } from "vue";

// 判斷當前是否為移動設備
const isMobile = window.matchMedia("(max-width: 768px)").matches;

// 動態導入相應的組件
const AppHeader = defineAsyncComponent(() =>
    import(`@/components/${isMobile ? "mobile" : "desktop"}/AppHeader.vue`)
);
const AppFooter = defineAsyncComponent(() =>
    import(`@/components/${isMobile ? "mobile" : "desktop"}/AppFooter.vue`)
);

// 創建響應式引用，用於設置 main 元素的樣式
const marginTop = ref(isMobile ? "48px" : "60px");
const minHeight = ref(isMobile ? "calc(100vh - 284px)" : "calc(100vh - 156px)");

// 更新 main 元素的樣式
const updateMainStyle = () => {
    marginTop.value = isMobile ? "48px" : "60px";
    minHeight.value = isMobile ? "calc(100vh - 284px)" : "calc(100vh - 156px)";
};

// 在組件掛載時更新樣式
onMounted(updateMainStyle);
</script>

<style>
body {
    margin: 0px;
    color: #333333; /* 深灰色文字 */
    background-color: #f8f8f8; /* 淡色背景 */
}

@media screen and (min-width: 768px) {
    main {
        width: 60%;
        max-width: 1920px; /* 若70%大於1920時則固定為1920 */
        margin: 0 auto; /* 讓內容水平置中 */
    }
}

.banner {
    width: 100%;
    height: auto;
}

.input-container {
    width: 100%; /* 容器寬度為100% */
    gap: 8px;
}

.styled-input::placeholder,
.styled-input-wrapper::placeholder {
    color: #cccccc;
    opacity: 1; /* Firefox 需要设置不透明度 */
}
/* Internet Explorer 和 Edge 旧版本 */
.styled-input:-ms-input-placeholder,
.styled-input-wrapper:-ms-input-placeholder {
    color: #cccccc;
}
/* Safari 和 Edge 较旧版本的兼容写法 */
.styled-input::-webkit-input-placeholder,
.styled-input-wrapper::-webkit-input-placeholder {
    color: #cccccc;
}
.styled-input:disabled {
    background: #f2f2f2; /* 灰色背景，表明不可互動 */
    color: #b7b7b7; /* 文字顏色更淺，表示不可編輯 */
    cursor: not-allowed; /* 光標展示為不可點擊狀態 */
}
/* 一般輸入框樣式 */
.styled-input {
    width: 100%;
    padding: 12px 16px; /* 內邊距 */
    border-radius: 4px; /* 圓角 */
    border: 1px solid #999999; /* 邊框 */
    outline: none; /* 移除預設外框 */
    color: #333333; /* 文字顏色 */
    font-size: 14px; /* 文字大小 */
    box-sizing: border-box; /* 邊框盒模型 - 使寬高包含內容、內邊距和邊框 */
}

.styled-input:focus {
    border-color: #b0a8a1;
}

.radio-group {
    display: flex;
    flex-direction: column;
}

.radio-row {
    display: flex;
    justify-content: center;
}

label {
    margin-right: 10px; /* 根据需要调整间距 */
    min-width: 60px;
    text-align: left;
}
</style>
