<template>
    <!-- 志工招募 -->
    <div class="top_wrapper">
        <div class="page_title">志工招募</div>
        <img class="banner" src="@/assets/banner/bn-volunteer.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="text_section">
        <div>
            成為流浪動物之家的一份子，給予這些無家可歸的毛孩們關愛和希望。您的參與不僅為它們帶來溫暖，也讓您的生活更加充實與有意義。
        </div>
        <div>
            通過志工服務，為這些需要幫助的動物提供關懷和支持。您的每一個小小行動都能在它們的生命中留下深刻印記，一同為推動動物福利盡一份力。
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.text_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 16px 24px;
    font-size: 15px;
    gap: 24px;
}
</style>
