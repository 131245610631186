<template>
    <!-- 聯絡我們 -->
    <div class="top_wrapper">
        <div class="page_title">聯絡我們</div>
        <img class="banner" src="@/assets/banner/bn-contact.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="text_section">
        <div>E-mail:warmdog2021@gamil.com</div>
        <div>LINE@:@warm.tw(點我加入好友)</div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.text_section {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 0 24px 16px 24px;
    font-size: 15px;
    gap: 6px;
}
</style>
