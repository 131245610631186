<template>
    <!-- 專欄文章一Detail -->
    <h4>除了愛與憐憫 流浪動物問題我們認識多少？</h4>
    <div class="top_wrapper">
        <img class="banner" src="@/assets/banner/bn-blog.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="text_section">
        一邊梳理各方論述與觀點外，另一邊回顧過去窩窩曾製作的相關流浪動物議題報導，諸如街犬貓的一生、TNVR入法、零撲殺、人犬衝突、餵養、貓狗大戰等。喜的是我們能夠有別於一般大眾傳媒，想藉由每一次的專題報導更全面地去探討流浪動物現況與問題，激發出不同的討論與思辨；憂的是作為媒體，每跨出舒適圈，嘗試擔任眾多利害關係者對話的居中詮釋角色、忠實呈現現況。
        在談源頭管理前，或許我們更應積極去了解一地犬隻的組成，是野犬、放養家犬、還是棄養或走失犬？才能衡量該地犬隻族群與週遭環境的關聯等，進而正視每一地區所面臨的流浪犬隻問題何在，再決定以何種手段相互搭配來預防管理。
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
h4 {
    padding: 1em;
    margin: 0;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.text_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 16px 24px;
    font-size: 15px;
}
</style>
