<template>
    <!-- ATM轉帳 -->
    <div class="title">銀行匯款/ATM轉帳</div>
    <div class="text">
        <div class="info-title">捐款說明</div>
        <div class="section">
            <div>(1)匯款銀行:華南銀行(代碼008)</div>
            <div>帳號:136-1000-16837</div>
            <div>戶名:社團法人台灣有點暖毛孩協會</div>
        </div>
        <div class="section">
            若您需要收據，請在匯款存根或備註註明捐款者姓名、電話、地址，並透過以下方式聯繫我們，以利寄送捐款收據。
        </div>
        <div class="section">
            <div>如有問題請聯繫我們</div>
            <div>E-mail:warmdog2021@gamil.com</div>
            <div>LINE@:@warm.tw(點我加入好友)</div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title {
    font-size: 18px;
    font-weight: 700;
    padding: 24px;
    padding-bottom: 12px;
}

.info-title {
    font-weight: 700;
    margin-bottom: 16px;
    border-top: 1px solid #d2d2d2;
    padding-top: 16px;
}

.text {
    padding: 24px;
    padding-top: 0;
}

.section {
    margin-bottom: 16px;
    font-size: 14px;
}
</style>
