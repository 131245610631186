<style scoped></style>
<template>
    <!-- 線上助養 -->
    <div class="top_wrapper">
        <div class="page_title">線上助養</div>
        <img class="banner" src="@/assets/banner/bn-Sponsorship.jpg" alt="" />
    </div>

    <div class="donate_btn">
        <router-link :to="{ name: 'DonateHome' }">立即線上捐款</router-link>
    </div>

    <div class="card-section">
        <div class="feed-card">
            <div class="img-section">
                <img src="@/assets/feed02.png" alt="" />
            </div>
            <div class="text-section">
                <div class="sub-title">Jeff</div>
                <div class="sub-title">男生/2歲/新北市</div>
                <div class="sub-title">未被領養</div>
                <div class="sub-text">
                    今年一月原本在彰化流浪時被到下橡皮筋的Jeff,
                    愛媽接到彰化志工求助去電想要帶出幫忙醫療...
                </div>
                <div class="button-section">
                    <router-link :to="{ name: 'DonateHome' }"
                        >我要助養</router-link
                    >
                    <router-link :to="{ name: 'OnlineSponsorshipDetail' }"
                        >我要領養</router-link
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
.top_wrapper {
    width: 100%;
    position: relative;
    z-index: -1;
}

.page_title {
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translate(-50%);
    font-size: 24px;
    color: #fff;
    text-align: center;
}

.donate_btn {
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.donate_btn a {
    background-color: #b7aba1;
    color: #fff;
    border-radius: 4px;
    border: none;
    text-decoration: none;
    padding: 8px 48px;
    font-size: 14px;
}

.card-section {
    display: flex;
    justify-content: center;
    padding: 16px;
}

.card-section img {
    width: 100%;
    height: auto;
}

.feed-card {
    width: 70%;

    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 5px 2px #d3d3d3;
}

.text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 12px 24px;
}

.sub-title {
    font-weight: 600;
}

.sub-text {
    font-size: 12px;
}

.button-section {
    display: flex;
    gap: 12px;
}

.button-section a {
    background-color: #ffffff;
    color: #333333;
    border-radius: 4px;
    border: 1px solid #333333;
    text-decoration: none;
    font-size: 12px;
    padding: 6px 12px;
}
</style>
