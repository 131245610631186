import * as repo from "@/api";

export default {
    //auth
    async handleLogin(context, body = {}) {
        try {
            const res = await repo.apiPostTokenObtain(body);
            const token = res.data.access_token;

            localStorage.setItem("wwt", token);

            // 确保token已经加入localStorage
            if (localStorage.getItem("wwt")) {
                // 更新状態
                context.commit("setHadToken", true);
                context.commit("setToken", token);
            } else {
                console.log("Token not found in localStorage.");
            }
        } catch (error) {
            console.log(error);
        }
    },

    async handleLogout(context) {
        try {
            localStorage.removeItem("wwt");

            // 更新状態
            context.commit("clearToken");
            context.commit("setHadToken", false);
        } catch (error) {
            console.error(error);
        }
    },

    //common
    async handleGetProfile() {
        try {
            const res = await repo.apiGetUserSelf();
            return res.data.results;
        } catch (error) {
            console.error(error);
        }
    },

    async handleGetShop(_, { page, limit }) {
        try {
            const res = await repo.apiGetShop(page, limit);
            return res.data.results;
        } catch (error) {
            console.error(error);
        }
    },

    async handleGetDonateFood() {
        try {
            const res = await repo.apiGetDonateFood();
            return res.data.results;
        } catch (error) {
            console.error(error);
        }
    },

    async handleGetDonateMaterial() {
        try {
            const res = await repo.apiGetDonateMaterial();
            return res.data.results;
        } catch (error) {
            console.error(error);
        }
    },

    async handleGetHomeVideo(_, { page, limit }) {
        try {
            const res = await repo.apiGetHomeVideo(page, limit);
            return res.data.results;
        } catch (error) {
            console.error(error);
        }
    },

    async handleGetFaqList(_, { page, limit }) {
        try {
            const res = await repo.apiGetFaqList(page, limit);
            return res.data.results;
        } catch (error) {
            console.error(error);
        }
    },

    async handleGetYearlyReport(_, { page, limit }) {
        try {
            const res = await repo.apiGetYearlyReport(page, limit);
            return res.data.results;
        } catch (error) {
            console.error(error);
        }
    },

    async handleGetDonationRecords() {
        try {
            const res = await repo.apiGetDonationRecords();
            console.log(res);
            return res.data.results;
        } catch (error) {
            console.error(error);
        }
    },

    async handlePatchUserId(_, { id, body }) {
        try {
            const res = await repo.apiPatchUserId({ id, body });
            console.log(res);
        } catch (error) {
            console.error(error);
        }
    },

    //cash
    async handlePostEcpayFood(_, body = {}) {
        try {
            const res = await repo.apiPostEcpayFood(body);
            return res;
        } catch (error) {
            console.error(error);
        }
    },

    async handlePostEcpayMaterial(_, body = {}) {
        try {
            const res = await repo.apiPostEcpayMaterial(body);
            return res;
        } catch (error) {
            console.error(error);
        }
    },

    async handlePostEcpayShop(_, body = {}) {
        try {
            const res = await repo.apiPostEcpayShop(body);
            return res;
        } catch (error) {
            console.error(error);
        }
    },
};
