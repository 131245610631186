<template>
    <form @submit.prevent="validateForm">
        <div class="card">
            <div class="title">個人檔案</div>
            <div class="card-content">
                <div class="field-session">
                    <div class="title required-field">電子郵件</div>
                    <div class="input-container">
                        <input
                            type="text"
                            v-model="profile.email"
                            class="styled-input"
                            placeholder="abc@gmail.com"
                            name="email"
                        />
                    </div>
                </div>
                <div class="field-session">
                    <div class="title required-field">姓名</div>
                    <div class="input-container">
                        <input
                            type="text"
                            v-model="profile.nickname"
                            class="styled-input"
                            placeholder="林先生"
                            name="nickname"
                        />
                    </div>
                </div>
                <div class="field-session">
                    <div class="title required-field">手機</div>
                    <div class="input-container">
                        <input
                            type="number"
                            v-model="profile.phone"
                            class="styled-input"
                            placeholder="0912345678"
                            name="phone"
                        />
                    </div>
                </div>
                <div class="field-session">
                    <div class="title">身分證字號/居留證號</div>
                    <div class="input-container">
                        <input
                            type="text"
                            v-model="profile.identity"
                            class="styled-input"
                            placeholder="F100123456"
                            name="identity"
                        />
                    </div>
                </div>
                <div class="field-session">
                    <div class="title">地址</div>
                    <div class="input-container">
                        <input
                            type="text"
                            v-model="profile.address"
                            class="styled-input"
                            placeholder="新北市板橋區文化路一號"
                            name="address"
                        />
                    </div>
                </div>
                <div class="field-session">
                    <div class="title">加入時間</div>
                    <div>{{ profile.date_joined }}</div>
                </div>
            </div>
        </div>
        <button type="submit" class="submit">確認修改</button>
    </form>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
export default {
    setup() {
        const store = useStore();

        const profile = ref({
            address: "",
            date_joined: "",
            email: "c24312265@gmail.com",
            id: -1,
            identity: "",
            nickname: "",
            phone: "",
            username: "",
        });

        const errors = reactive({});

        const handleGetProfile = () => {
            store
                .dispatch("handleGetProfile")
                .then((res) => {
                    profile.value = res;
                    const isoDateString = profile.value.date_joined;
                    const dateObject = new Date(isoDateString);
                    const formattedDate = dateObject.toLocaleDateString(
                        "sv-SE",
                        {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                        }
                    );
                    profile.value.date_joined = formattedDate;
                })
                .catch((error) => {
                    console.error("handleGetProfile failed:", error);
                });
        };

        const validateForm = () => {
            let valid = true;
            errors.value = {};

            if (!profile.value.email) {
                errors.email = "請輸入電子郵件";
                valid = false;
            } else if (!/\S+@\S+\.\S+/.test(profile.value.email)) {
                errors.email = "请输入有效的電子郵件";
                valid = false;
            }

            if (valid) {
                console.log("表單ＯＫ");
                handlePatchUserId();
            } else {
                console.log(errors);
            }
        };

        const handlePatchUserId = () => {
            console.log(profile.value.id, profile.value);
            store
                .dispatch("handlePatchUserId", {
                    id: profile.value.id,
                    body: {
                        nickname: profile.value.nickname,
                        email: profile.value.email,
                        phone: profile.value.phone,
                        identity: profile.value.identity,
                        address: profile.value.address,
                    },
                })
                .then(() => {
                    handleGetProfile();
                })
                .catch((error) => {
                    console.error("handlePatchUserId failed:", error);
                });
        };

        onMounted(() => {
            handleGetProfile();
        });

        return {
            profile,
            validateForm,
        };
    },
};
</script>

<style scoped>
form {
    padding: 24px;
    text-align: center;
}
.card {
    padding: 16px;
    box-shadow: 0 0 4px 2px #d3d3d3;
    border-radius: 4px;
}

.title {
    font-size: 18px;
    font-weight: 600;
}

.card-content {
    padding: 16px;
    text-align: left;
}

.field-session:not(:last-child) {
    margin-bottom: 12px;
}

.field-session .title {
    font-size: 14px;
}

.required-field::after {
    content: "*";
    color: red;
    margin-left: 4px;
}

.submit {
    margin-top: 24px;
    border-radius: 4px;
    width: 230px;
    height: 36px;
    background-color: #b7aba1;
    border: 0.3pt solid #bebcbc;
    text-align: center;
    color: #fff;
}
</style>
