import { useStore } from "vuex";

import { createRouter, createWebHistory } from "vue-router";
import HomeMobile from "@/views/mobile/HomePage.vue";

import AssociationIntro from "@/views/mobile/AssociationIntro.vue";
import FaqPage from "@/views/mobile/FaqPage.vue";
import AnnualReport from "@/views/mobile/AnnualReport.vue";
import DonationBoxMap from "@/views/mobile/DonationBoxMap.vue";

import LogIn from "@/views/mobile/LogIn.vue";
import RegisterAccount from "@/views/mobile/RegisterAccount.vue";
import ForgotPassword from "@/views/mobile/ForgotPassword.vue";
import DonateHome from "@/views/mobile/DonateHome.vue";
import OnlinePayment from "@/views/mobile/OnlinePayment.vue";
import RegularDonation from "@/views/mobile/RegularDonation.vue";
import PostalRemittance from "@/views/mobile/PostalRemittance.vue";
import ATMTransfer from "@/views/mobile/ATMTransfer.vue";
import OnlineFoodDonation from "@/views/mobile/OnlineFoodDonation.vue";
import OnlineSponsorship from "@/views/mobile/OnlineSponsorship.vue";
import OnlineSponsorshipDetail from "@/views/mobile/OnlineSponsorshipDetail.vue";
import MaterialDonation from "@/views/mobile/MaterialDonation.vue";
import OnlineShopping from "@/views/mobile/OnlineShopping.vue";
import VolunteerRecruitment from "@/views/mobile/VolunteerRecruitment.vue";
import ContactUs from "@/views/mobile/ContactUs.vue";
import UserProfile from "@/views/mobile/UserProfile.vue";
import MemberRecord from "@/views/mobile/MemberRecord.vue";
import ArticleList from "@/views/mobile/ArticleList.vue";
import ArticleDetail from "@/views/mobile/ArticleDetail.vue";

// import HomeDesktop from "@/views/desktop/Home.vue";

// function isMobileDevice() {
//     const userAgent = navigator.userAgent.toLowerCase();
//     return /mobile|android|touch|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
//         userAgent
//     );
// }

const routes = [
    {
        path: "/login",
        children: [
            {
                path: "",
                name: "LogIn",
                component: LogIn,
            },
            {
                path: "register",
                name: "RegisterAccount",
                component: RegisterAccount,
            },
            {
                path: "forgot",
                name: "ForgotPassword",
                component: ForgotPassword,
            },
        ],
    },
    {
        path: "/",
        name: "Home",
        // component: isMobileDevice() ? HomeMobile : HomeDesktop,
        component: HomeMobile,
    },
    {
        path: "/about",
        children: [
            {
                path: "",
                name: "AssociationIntro",
                component: AssociationIntro, //關於協會 - 協會介紹
            },
            {
                path: "questions",
                name: "FaqPage",
                component: FaqPage, //常見問題
            },
            {
                path: "report",
                name: "AnnualReport",
                component: AnnualReport, //年度報告
            },
            {
                path: "boxmap",
                name: "DonationBoxMap",
                component: DonationBoxMap, //募捐箱地圖
            },
        ],
    },
    {
        path: "/donate",
        children: [
            {
                path: "",
                name: "DonateHome",
                component: DonateHome, //線上捐款
            },
            {
                path: "online",
                name: "OnlinePayment",
                component: OnlinePayment, //線上刷卡
            },
            {
                path: "regular",
                name: "RegularDonation",
                component: RegularDonation, //定期捐款
            },
            {
                path: "post",
                name: "PostalRemittance",
                component: PostalRemittance, //郵政劃撥
            },
            {
                path: "atm",
                name: "ATMTransfer",
                component: ATMTransfer, //ATM轉帳
            },
        ],
    },
    {
        path: "/food",
        name: "OnlineFoodDonation",
        component: OnlineFoodDonation, //線上捐糧
    },
    {
        path: "/feed",
        children: [
            {
                path: "",
                name: "OnlineSponsorship",
                component: OnlineSponsorship, //物資捐贈
            },
            {
                path: "inner",
                name: "OnlineSponsorshipDetail",
                component: OnlineSponsorshipDetail, //線上購物
            },
        ],
    },
    {
        path: "/public",
        children: [
            {
                path: "",
                name: "MaterialDonation",
                component: MaterialDonation, //物資捐贈
            },
            {
                path: "shopping",
                name: "OnlineShopping",
                component: OnlineShopping, //線上購物
            },
        ],
    },
    {
        path: "/volunteer",
        name: "VolunteerRecruitment",
        component: VolunteerRecruitment,
    },
    {
        path: "/contact",
        name: "ContactUs",
        component: ContactUs,
    },
    {
        path: "/userprofile",
        name: "UserProfile",
        component: UserProfile,
    },
    {
        path: "/memberrecord",
        name: "MemberRecord",
        component: MemberRecord,
    },
    {
        path: "/more",
        children: [
            {
                path: "",
                name: "ArticleList",
                component: ArticleList, //專欄文章一覽
            },
            {
                path: "inner",
                name: "ArticleDetail",
                component: ArticleDetail, //專欄文章
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const store = useStore();

    const isLoggedIn = store.getters.getHadToken;

    const restrictedRoutes = ["UserProfile", "MemberRecord"];

    if (!isLoggedIn && restrictedRoutes.includes(to.name)) {
        next({ name: "Home" });
    } else {
        next(); // 放行
    }
});

export default router;
