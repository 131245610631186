import * as auth from "./auth.js";
import * as common from "./common.js";
import * as cash from "./cash.js";

export const apiPostTokenObtain = auth.postTokenObtain;

export const apiGetUserSelf = common.getUserSelf;
export const apiGetShop = common.getShop;
export const apiGetDonateFood = common.getDonateFood;
export const apiGetDonateMaterial = common.getDonateMaterial;

export const apiGetHomeVideo = common.getHomeVideo;
export const apiGetFaqList = common.getFaqList;
export const apiGetYearlyReport = common.getYearlyReport;
export const apiGetDonationRecords = common.getDonationRecords;

export const apiPatchUserId = common.patchUserId;

export const apiPostEcpayFood = cash.postEcpayFood;
export const apiPostEcpayMaterial = cash.postEcpayMaterial;
export const apiPostEcpayShop = cash.postEcpayShop;
